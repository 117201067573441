import { track } from '@vercel/analytics'
import { signIn } from 'next-auth/react'

import { Footer } from '~/components/footer'
import { AppleLogo, GoogleLogo } from '~/components/logo'

export default function Example() {
	return (
		<>
			<div className="flex min-h-screen flex-1 flex-col items-center justify-center bg-slate-900 px-4">
				<div className="mx-4 flex w-full flex-col justify-center rounded-xl border border-amber-400 bg-slate-600 px-6 py-12 md:mx-auto md:max-w-sm lg:px-8">
					<div className="sm:mx-auto sm:w-full sm:max-w-sm">
						<img
							className="mx-auto h-10 w-auto"
							src="/icon_512x512@2x.png"
							alt="Your Company"
						/>
						<h2 className="font-display mt-10 text-center text-2xl font-bold leading-9 text-slate-50">
							Message De<span className="font-mono tracking-tight">code</span>r
						</h2>
					</div>

					<div className="mt-10 flex flex-col gap-y-4 sm:mx-auto sm:w-full sm:max-w-sm">
						<button
							className="flex justify-between rounded-md bg-black px-3.5 py-2.5 text-sm font-semibold text-gray-50 shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
							onClick={async () => {
								track('sign-in', { provider: 'apple' })
								return signIn('apple', {
									callbackUrl: `${window.location.origin}/home`,
								})
							}}
							type="button"
						>
							<AppleLogo
								className="absolute inline-flex aspect-square h-6 text-white"
								currentColor
							/>
							<span className="ml-9 w-full font-medium">Continue with Apple</span>
						</button>
						<button
							className="flex justify-between rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
							onClick={async () => {
								track('sign-in', { provider: 'google' })
								return signIn('google', {
									callbackUrl: `${window.location.origin}/home`,
								})
							}}
							type="button"
						>
							<GoogleLogo className="absolute inline-flex aspect-square h-6" />
							<span className="ml-9 w-full font-medium">Continue with Google</span>
						</button>
					</div>
				</div>
			</div>
			<Footer />
		</>
	)
}
